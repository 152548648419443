import { Injectable } from '@angular/core';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';
import { Pwa } from '@freelancer/pwa';
import { getFileType, ModalService } from '@freelancer/ui';
import type { ModalResult } from '@freelancer/ui/modal';
import { ModalSize } from '@freelancer/ui/modal';
import { ToastAlertService } from '@freelancer/ui/toast-alert';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, firstValueFrom, map, shareReplay } from 'rxjs';

export interface FileViewerInputs {
  src: string;
  /**
   * To be used when the viewing source is different to the one that should
   * be downloaded when clicking the download button.
   * For example: we show webp images in the browser, but when clicking
   * download it should return the original file format.
   */
  alternativeDownloadSrc?: string;
  filename: string;
}

@UntilDestroy({ className: 'FileViewer' })
@Injectable({
  providedIn: 'root',
})
export class FileViewer {
  private loadingAlertId = 'file-viewer-loading';

  private afterOpenPromise?: Promise<void>;
  private afterClosedPromise?: Promise<ModalResult>;

  constructor(
    private previewAnyFile: PreviewAnyFile,
    private pwa: Pwa,
    private modalService: ModalService,
    private toastAlertService: ToastAlertService,
  ) {}

  async open(inputs: FileViewerInputs): Promise<void> {
    /**
     * Use previewAnyFile plugin for native apps that supports
     * more file types which also has zoom and pan actions.
     *
     * Note: This fallbacks to the modal if the previewAnyFile
     * plugin is not available
     */
    if (this.pwa.isNative() && this.pwa.getPlatform() === 'ios') {
      this.toastAlertService.open(this.loadingAlertId);

      const previewResult$ = this.previewAnyFile
        .previewPath(inputs.src, {
          name: inputs.filename,
        })
        .pipe(
          // replay to both open and closed events
          shareReplay({ bufferSize: 1, refCount: true }),
        );

      this.afterOpenPromise = firstValueFrom(
        previewResult$.pipe(
          filter(result => result === 'SUCCESS' || result === 'NO_APP'),
          map(() => undefined),
          untilDestroyed(this),
        ),
      );

      this.afterClosedPromise = firstValueFrom(
        previewResult$.pipe(
          filter(result => result === 'CLOSING'),
          map(() => undefined),
          untilDestroyed(this),
        ),
      );

      await this.afterOpenPromise;
      this.toastAlertService.close(this.loadingAlertId);
      return;
    }

    /**
     * For other cases, this will open a file preview modal by default.
     */
    this.openFileViewerModal(inputs);
  }

  private openFileViewerModal(inputs: FileViewerInputs): void {
    const { filename } = inputs;

    const fileViewerModal = this.modalService.open('FileViewerModal', {
      inputs: {
        filetype: getFileType(filename),
        ...inputs,
      },
      edgeToEdge: true,
      size: ModalSize.XLARGE,
    });

    this.afterOpenPromise = fileViewerModal.afterOpen();
    this.afterClosedPromise = fileViewerModal.afterClosed();
  }

  afterOpen(): Promise<void> {
    if (!this.afterOpenPromise) {
      throw new Error('Tried to check afterOpen without opening file viewer');
    }

    return this.afterOpenPromise;
  }

  afterClosed(config?: {
    reopenOtherModal: boolean;
    index?: number;
  }): Promise<ModalResult> {
    if (!this.afterClosedPromise) {
      throw new Error('Tried to check afterClosed without opening file viewer');
    }

    return this.afterClosedPromise.then(result => {
      if (config?.reopenOtherModal) {
        this.modalService.openModalInStack(config?.index);
      }
      return result;
    });
  }
}
